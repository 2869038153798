@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
body {
  background-color: #F5F5F5;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-padding-top: 100px;
  overflow-x: hidden !important;
  max-width: 100% !important; 
  scroll-behavior: smooth;
}


@media (min-width: 1060px) {
  .Hidden {
    display: none !important;
  }
}
@media (max-width: 1060px) {
  .Visible {
    display: none !important;
  }
}